import React from 'react';
import Image from 'next/image';
import Container from './Container';
import Button from './Button';
import { config } from '../config';

function HomeHero() {
  return (
    <div className='bg-gradient-to-b from-primary-500 to-primary-600'>
      <Container className='relative'>
        <div className='relative max-w-2xl pt-64 lg:pb-40 pb-[400px]'>
          <h1 className='text-[48px] leading-[52px] md:text-[56px] md:leading-[64px] font-bold text-white mb-5'>
            Welcome to the<br></br> Heepow Herd!
          </h1>
          <p className='mb-8 text-2xl text-white text-opacity-75'>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            Our signature “OG” collection consists of 254 unique 1/1 NFTs. Our v2 Brats Collection is a collection of 2400 generated NFTs, and 180 1/1
            Brats. Our v3 Deviant Collection will be a collection of 2400 generated NFTs. All are only available on the Algorand blockchain!
          </p>
          <div className='flex flex-col space-y-5 md:space-x-6 md:flex-row md:space-y-0'>
            <Button theme='white' href={config.buyNow} target={'_blank'}>
              Buy a Heepow
            </Button>
            <Button theme='outline-white' href={config.buyNowBrat} target={'_blank'}>
              Buy a Brat
            </Button>
          </div>
        </div>
        <div className='absolute bottom-0 right-0 object-cover md:w-[475px] md:h-[475px] mb-[-6px] md:mb-0'>
          <Image src='/heepows/1.png' alt='Heepow' width={500} height={500} />
        </div>
      </Container>
    </div>
  );
}

export default HomeHero;
