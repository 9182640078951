import Image from 'next/image';
import { config } from '../config';

export default function Team() {
  return (
    <div className=''>
      <div className='max-w-4xl px-4 py-12 mx-auto sm:px-6 lg:px-8 lg:py-36'>
        <div className='space-y-12'>
          <div className='mx-auto space-y-5 text-center sm:space-y-4 md:max-w-xl lg:max-w-2xl'>
            <h2 className='text-4xl font-extrabold tracking-tight'>Meet the Team</h2>
            <p className='text-2xl text-gray-500'>
              The whole team has a deep passion for animals and for helping people, which is why the Heepow Herd is focused on both.
            </p>
          </div>

          <div>
            <ul role='list' className='space-y-4 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:gap-10'>
              <div key={config.teamLead.name} className='px-6 py-10 text-center rounded-lg bg-secondary-500 xl:px-10 xl:text-left col-span-2'>
                <div className='space-y-6 xl:space-y-10'>
                  {config.teamLead.imageUrl && (
                    <div className='w-40 h-40 mx-auto xl:w-56 xl:h-56'>
                      <Image className='rounded-full' src={config.teamLead.imageUrl} alt='' width={500} height={500} />
                    </div>
                  )}
                  <div className='space-y-2 xl:flex xl:items-center xl:justify-between'>
                    <div className='space-y-1 text-lg font-medium leading-6'>
                      <h3 className='font-bold text-white'>{config.teamLead.name}</h3>
                      <p className='text-white text-opacity-50'>{config.teamLead.role}</p>
                    </div>

                    {config.teamLead.twitterUrl && (
                      <ul role='list' className='flex justify-center space-x-5'>
                        <li>
                          <a href={config.teamLead.twitterUrl} className='text-white text-opacity-70 hover:text-opacity-100'>
                            <span className='sr-only'>Twitter</span>
                            <svg className='w-5 h-5' aria-hidden='true' fill='currentColor' viewBox='0 0 20 20'>
                              <path d='M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84' />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
              {config.team.map((person) => (
                <li key={person.name} className='px-6 py-10 text-center rounded-lg bg-secondary-500 xl:px-10 xl:text-left'>
                  <div className='space-y-6 xl:space-y-10'>
                    {person.imageUrl && (
                      <div className='w-40 h-40 mx-auto xl:w-56 xl:h-56'>
                        <Image className='rounded-full' src={person.imageUrl} alt='' width={500} height={500} />
                      </div>
                    )}
                    <div className='space-y-2 xl:flex xl:items-center xl:justify-between'>
                      <div className='space-y-1 text-lg font-medium leading-6'>
                        <h3 className='font-bold text-white'>{person.name}</h3>
                        <p className='text-white text-opacity-50'>{person.role}</p>
                      </div>

                      {person.twitterUrl && (
                        <ul role='list' className='flex justify-center space-x-5'>
                          <li>
                            <a href={person.twitterUrl} className='text-white text-opacity-70 hover:text-opacity-100'>
                              <span className='sr-only'>Twitter</span>
                              <svg className='w-5 h-5' aria-hidden='true' fill='currentColor' viewBox='0 0 20 20'>
                                <path d='M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84' />
                              </svg>
                            </a>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className='mt-16'>
          <p className='text-2xl text-gray-500'>
            This project would not be possible without the art and vision of Francis (MyAlgoHeepow) and his wife Jane! We hope to honor their vision
            and desire for Heepow to be a top project on Algorand with real life utility. Much love!{' '}
          </p>
        </div>
      </div>
    </div>
  );
}
