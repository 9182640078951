import { config } from '../config';
import Container from './Container';

export default function Example() {
  return (
    <Container className='relative z-10'>
      <div className='p-5 rounded-md bg-secondary-500 md:p-0'>
        <div className='py-16 mx-auto sm:px-6 sm:pt-20 sm:pb-24 lg:pt-24 lg:px-8'>
          <h2 className='text-3xl font-extrabold tracking-tight text-white'>Why buy a Heepow NFT?</h2>
          <p className='max-w-3xl mt-4 text-xl text-white text-opacity-75'>There are many benefits to owning a Heepow NFT. Check ‘em out.</p>
          <div className='grid grid-cols-1 mt-12 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-3 lg:gap-x-12 lg:gap-y-12'>
            {config.features.map((feature) => (
              <div className='mt-6' key={feature}>
                <h3 className='text-lg font-bold text-white'>{feature.name}</h3>
                <p className='mt-2 text-base text-white text-opacity-75'>{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
}
