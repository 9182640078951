import React from 'react';
import Image from 'next/image';
import Container from './Container';

function Callout() {
  return (
    <div className='bg-opacity-25 bg-primary-500'>
      <Container>
        <div className='relative'>
          <div className='grid grid-cols-12 pt-12 pb-[400px] md:py-24 lg:py-48'>
            <div className='col-span-12 md:col-span-6'>
              <div className='max-w-lg'>
                <h2 className='text-3xl font-bold lg:text-4xl'>Giving back to the world</h2>
                <p className='mt-4 text-xl text-black text-opacity-50 lg:text-2xl'>
                  The Heepow team is dedicated to being a leading example in the Algorand NFT space by giving back to the world.
                </p>
                <p className='mt-4 text-xl text-black text-opacity-50 lg:text-2xl'>
                  We donate a portion of sales to zoos and other non-profit organizations. We look to work with Zoos and nature conservation groups to
                  further growth and research with IRL implications.
                </p>
              </div>
            </div>
          </div>

          <div className='absolute right-0 bottom-0 object-cover w-[350px] h-[350px] lg:w-[475px] lg:h-[475px]'>
            <Image src='/heepows/3.png' alt='Heepow' width={500} height={500} />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Callout;
