import React from 'react';
import Image from 'next/image';
import Container from './Container';

function About() {
  return (
    <Container>
      <div className='relative -mb-2'>
        <div className='grid grid-cols-12 pt-16 pb-[350px] md:pb-[300px] lg:py-52'>
          <div className='col-span-12 md:col-span-6 md:col-start-7'>
            <div className='md:max-w-lg'>
              <h2 className='text-4xl font-bold'>
                We are an NFT <br></br>project with a mission.
              </h2>
              <p className='mt-4 text-2xl text-gray-500'>
                This project is for those wanting to make a global impact, while learning and enjoying the Algorand NFT space.
              </p>
            </div>
          </div>
        </div>

        <div className='absolute left-0 bottom-0 object-cover max-w-[450px] max-h-[450px] lg:w-[475px] lg:h-[475px]'>
          <Image src='/heepows/2.png' alt='Heepow' width={500} height={500} />
        </div>
      </div>
    </Container>
  );
}

export default About;
