import Button from './Button';
import Container from './Container';
import { config } from '../config';

export default function ReadyToJoin() {
  return (
    <div className='bg-gray-50'>
      <Container>
        <div className='py-12 mx-auto sm:px-6 lg:py-16 lg:flex lg:items-center lg:justify-between'>
          <h2 className='text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
            <span className='block'>Ready to join the </span>
            <span className='block text-secondary-500'>Heepow Herd?</span>
          </h2>
          <div className='block mt-8 lg:mt-0 lg:flex-shrink-0'>
            <div className='flex flex-col space-y-5 md:space-x-10 md:flex-row md:space-y-0'>
              <Button theme='primary' href={config.buyNow} target={'_blank'}>
                Buy a Heepow
              </Button>
              <Button theme='outline' href={config.discord} target={'_blank'}>
                Join Discord
              </Button>
              <Button theme='outline' href={config.twitter} target={'_blank'}>
                Twitter
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
