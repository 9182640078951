/* eslint-disable react/no-unescaped-entities */
import { config } from '../config';
import Container from './Container';

export default function Faqs() {
  return (
    <div className='relative bg-primary-500 pb-40 pt-[375px] mt-[-250px] bg-gradient-to-b from-primary-500 to-primary-600'>
      <Container>
        <div>
          <div className='lg:grid lg:grid-cols-3 lg:gap-16'>
            <div>
              <h2 className='text-3xl font-extrabold text-white'>Frequently asked questions</h2>
              <p className='mt-4 text-xl text-white text-opacity-75'>
                Can't find the answer you're looking for? Reach out to us in{' '}
                <a className='font-bold text-white' href={config.discord} target='_blank' rel='noreferrer'>
                  Discord
                </a>{' '}
                and we'll be glad to answer your questions.
              </p>
            </div>
            <div className='mt-12 lg:mt-0 lg:col-span-2'>
              <dl className='grid grid-cols-1 gap-10 md:grid-cols-2'>
                {config.faqs.map((faq) => (
                  <div key={faq.question}>
                    <dt className='text-lg font-bold leading-6 text-white'>{faq.question}</dt>
                    <dd className='mt-2 text-base text-white text-opacity-75'>{faq.answer}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
