import Link from 'next/link';
import React from 'react';
import Spinner from './Spinner';

function Button({ children, onClick, href, target, theme = 'primary', className, link, type = 'button', disabled, loading }) {
  let themeClasses = 'text-white bg-primary-500 border-primary-500';

  if (theme === 'dark') {
    themeClasses = 'text-white bg-secondary-500 border-secondary-500 hover:text-secondary-500';
  }

  if (theme === 'outline') {
    themeClasses = 'text-primary-500 border-primary-500 hover:bg-primary-500 hover:text-white';
  }

  if (theme === 'white') {
    themeClasses = 'text-primary-500 bg-white border-white hover:bg-primary-500 hover:text-white hover:border-primary-500';
  }

  if (theme === 'danger') {
    themeClasses = 'text-red-500 border-red-500 hover:bg-red-500 hover:text-white';
  }

  if (theme === 'outline-white') {
    themeClasses = 'text-white border-white hover:bg-primary-500 hover:border-primary-500';
  }

  let classes = `inline-flex items-center px-10 py-3 border-2 text-sm font-bold uppercase rounded-full transition-all duration-200 tracking-wide text-center justify-center ${themeClasses} ${className}`;

  if (disabled) {
    classes += ' cursor-not-allowed opacity-50';
  }

  if (link && href) {
    return (
      <Link href={href} target={target}>
        <a className={classes}>{children}</a>
      </Link>
    );
  }

  if (href) {
    return (
      <a href={href} target={target} className={classes}>
        {children}
        {target === '_blank' && (
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='currentColor' className='ml-2 w-[12px] h-[12px]'>
            <path d='M384 320c-17.67 0-32 14.33-32 32v96H64V160h96c17.67 0 32-14.32 32-32s-14.33-32-32-32L64 96c-35.35 0-64 28.65-64 64V448c0 35.34 28.65 64 64 64h288c35.35 0 64-28.66 64-64v-96C416 334.3 401.7 320 384 320zM502.6 9.367C496.8 3.578 488.8 0 480 0h-160c-17.67 0-31.1 14.32-31.1 31.1c0 17.67 14.32 31.1 31.99 31.1h82.75L178.7 290.7c-12.5 12.5-12.5 32.76 0 45.26C191.2 348.5 211.5 348.5 224 336l224-226.8V192c0 17.67 14.33 31.1 31.1 31.1S512 209.7 512 192V31.1C512 23.16 508.4 15.16 502.6 9.367z'></path>
          </svg>
        )}
      </a>
    );
  }

  return (
    <button type={type} onClick={onClick} className={classes} disabled={disabled}>
      {loading ? <Spinner dimensionClasses='w-5 h-5' /> : children}
    </button>
  );
}

export default Button;
