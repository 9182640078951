import HomeHero from '../components/HomeHero';
import Faqs from '../components/Faqs';
import Features from '../components/Features';
import Team from '../components/Team';
import Callout from '../components/Callout';
import ReadyToJoin from '../components/ReadyToJoin';
import { config } from '../config';
import { NextSeo } from 'next-seo';
import About from '../components/About';

export default function Home() {
  return (
    <div>
      <NextSeo
        title={`${config.collectionName} - An Algorand Community and NFT Collection`}
        description={`Our signature “OG” collection consists of 254 unique 1/1 NFTs. Our v2 Brats Collection is a collection of 2400 generated NFTs, and 180 1/1 Brats. Our v3 Deviant Collection will be a collection of 2400 generated NFTs. All are only available on the Algorand blockchain!`}
      />

      <HomeHero />
      <About />
      <Features />
      <Faqs />
      <Team />
      <Callout />
      <ReadyToJoin />
    </div>
  );
}
